import { useState } from "react";
import { parser } from "./utils";
import Editor from "@monaco-editor/react";
import CheckIcon from "./CheckIcon";

function App() {
  const [logJSON, setLogJSON] = useState("");
  const [query, setQuery] = useState("");

  const [showCopyCheck, setShowCopyCheck] = useState(false);

  const onCopyClick = () => {
    navigator.clipboard.writeText(query);
    setShowCopyCheck(true);

    setTimeout(() => {
      setShowCopyCheck(false);
    }, 2000);
  };

  return (
    <div className="text-white">
      <h1 className="text-center">GCP Log Parser</h1>
      <div className="mx-auto w-50">
        <div>
          <label className="block fw-bold" htmlFor="log-data">
            Enter Log JSON:
          </label>
          <Editor
            className="my-1 border"
            height="300px"
            language="json"
            value={logJSON}
            theme="vs-dark"
            onChange={setLogJSON}
          />

          <button
            className="btn btn-primary"
            onClick={() => setQuery(parser(logJSON))}
          >
            Parse
          </button>
        </div>

        <hr />

        <div>
          <label className="block fw-bold" htmlFor="query">
            Query:
          </label>

          <Editor
            className="my-1 border"
            height="300px"
            language="sql"
            theme="vs-dark"
            value={query}
          />
          <div>
            <button className="btn btn-success" onClick={onCopyClick}>
              Copy
            </button>
            {showCopyCheck && <CheckIcon className="mx-2" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
