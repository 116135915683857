export const parser = (logJSON) => {
  let logs = JSON.parse(logJSON);
  let visitedTraces = {};
  let first = true;
  let query = [];

  query.push("(");

  for (let log of logs) {
    if (!log.hasOwnProperty("trace")) {
      continue;
    }
    let trace = log["trace"];
    if (visitedTraces.hasOwnProperty(trace)) {
      continue;
    }
    if (!first) {
      query.push("OR");
    }
    query.push(`trace:"${trace}"`);
    visitedTraces[trace] = true;
    first = false;
  }

  query.push(")");

  return query.join("\n");
};
